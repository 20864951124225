<template>
  <div
    class="aircraftDetail__slider"
    :class="{'aircraftDetail__slider--vertical' : type === 'vertical'}"
  >
    <div class="aircraftDetail__slider-main">
      <div class="swiper-container swiper-top" ref="swiperTop">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) of gallery" :key="index">
            <span
              class="aircraftDetail__slider-main-item"
              :style="{backgroundImage: `url(${item})`}"
              @click="openLightboxOnSlide(activeSlide)"
            >
              <div class="aircraftDetail__slider-main-item-back">
                <span class="aircraftDetail__slider-main-item-icon">
                  <svg width="488.85" height="488.85" viewBox="0 0 488.85 488.85">
                    <defs><linearGradient id="eyeG" x1="244.425" y1="0" x2="244.425" y2="488.85"
                                          gradient-units="userSpaceOnUse"><stop
                      stop-color="#41FB96"/><stop offset="1" stop-color="#12A053"/></linearGradient></defs><path
                    d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                    fill="url(#eyeG)"/>
                  </svg>
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="aircraftDetail__slider-thumbs">
      <div class="swiper-container swiper-thumbs" ref="swiperThumbs">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) of gallery" :key="index">
            <div class="aircraftDetail__slider-thumbs-item" :style="{backgroundImage: `url(${item})`}">
              <div class="aircraftDetail__slider-main-item-back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FsLightbox
      :toggler="toggler"
      :slide="activeSlide"
      :sources="gallery"
      :onOpen="opened"
    />
  </div>
</template>

<script>
  import Swiper, { Navigation, Pagination, Thumbs } from 'swiper'
  import FsLightbox from 'fslightbox-vue'
  import photo1 from '@/assets/img/booking/1.jpg'
  import photo2 from '@/assets/img/booking/2.jpg'
  import photo3 from '@/assets/img/booking/3.jpg'
  import photo4 from '@/assets/img/booking/4.jpg'

  Swiper.use([Navigation, Pagination, Thumbs])

  export default {
    props: ['type', 'thumsSlides'],
    name: 'Slider',
    data: () => ({
      activeSlide: 1,
      toggler: true,
      topSwiper: null,
      thumbSwiper: null,
      gallery: [photo4, photo2, photo3, photo1, photo4, photo2, photo3],
    }),
    methods: {
      openLightboxOnSlide (number) {
        this.toggler = !this.toggler
      },
      opened () {
        const popup = document.querySelector('.fslightbox-container')
        document.querySelector('body').appendChild(popup)
      },
    },
    mounted () {
      const sliderType = this.type

      this.thumbSwiper = new Swiper('.swiper-thumbs', {
        slidesPerView: this.thumsSlides,
        spaceBetween: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: sliderType === 'vertical' ? 'vertical' : 'horizontal',
      })

      this.topSwiper = new Swiper('.swiper-top', {
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        thumbs: {
          swiper: this.thumbSwiper,
        },
      })

      this.topSwiper.on('slideChange', () => {
        this.activeSlide = this.topSwiper.activeIndex + 1
      })
    },
    destroyed () {
      this.topSwiper.destroy()
    },
    components: {
      FsLightbox,
    },
  }
</script>
