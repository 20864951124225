<template>
  <vue-scroll :ops="ops" :style="{width: '100%'}" class="bookingScrollWrap">
    <div class="bookingDashboard bookingDashboard--provider bookingDashboard--confirm">
      <div class="bookingDashboard__left">
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Booking date</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">03/03/19</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Booking number</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">444455k</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Booking date</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">03/03/19</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Flyer's name</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">Jessica Fisher</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Booking status</div>
          <div class="bookingDashboard__list-item-text">
          <span class="icon">
            <SvgIcon name="clock"/>
          </span>
            <span class="text">PENDING COMPLETION</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Origin (location)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Destination (location)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">McCarran international airport (Las Vegas, Nevada)</span>
          </div>
          <div class="bookingDashboard__list-item-button-wr">
            <div class="bookingDashboard__list-item-button active">One way</div>
            <div class="bookingDashboard__list-item-button">Round trip</div>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Flight date and time - outbound</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">04/03/19 5:40 PM</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Depart</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Land (estimated)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
      </div>

      <div class="bookingDashboard__right">
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Land (estimated)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Flight date and time - inbound</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">04/03/19 5:40 PM</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Depart</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Land (estimated)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">San Jose international airport (San Jose, California)</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Trip time (duration)</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">5h 30min</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Outbound</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">5h 30min</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Inbound</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">5h 30min</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Aircraft model</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">Boeing 707</span>
          </div>
        </div>
        <div class="bookingDashboard__list-item bookingDashboard__list-item--slider">
          <div class="bookingDashboard__list-item-caption">Aircraft photo</div>
          <Slider :type="'vertical'" :thumsSlides="4"/>
        </div>
        <div class="bookingDashboard__list-item">
          <div class="bookingDashboard__list-item-caption">Aircraft operator</div>
          <div class="bookingDashboard__list-item-text">
            <span class="text">FlyJets</span>
          </div>
        </div>

        <div class="bookingDashboard__list-item bookingDashboard__list-item--users">
          <div class="bookingDashboard__list-item-caption">FlyJets contact person</div>
          <div class="bookingDashboard__users">
            <router-link
              v-for="item of booking.flyJetsPerson"
              :key="item.id"
              class="bookingDashboard__users-item"
              to="#"
            >
              <div
                class="bookingDashboard__users-avatar"
                :style="{backgroundImage: `url(${item.photo})`}"
              >
              </div>
              <div class="bookingDashboard__users-info">
                <div class="bookingDashboard__users-name">{{item.name}}</div>
                <div class="bookingDashboard__users-phone">{{item.phone}}</div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="bookingDashboard__list-item bookingDashboard__list-item--users">
          <div class="bookingDashboard__list-item-caption">Passengers list <span>(if applicable)</span></div>
          <div class="bookingDashboard__users">
            <router-link
              v-for="item of booking.passengers"
              :key="item.id"
              class="bookingDashboard__users-item"
              to="#"
            >
              <div
                class="bookingDashboard__users-avatar"
                :style="{backgroundImage: `url(${item.photo})`}"
              >
              </div>
              <div class="bookingDashboard__users-info">
                <div class="bookingDashboard__users-name">{{item.name}}</div>
                <div class="bookingDashboard__users-phone">{{item.phone}}</div>
              </div>
            </router-link>
          </div>
        </div>

      </div>
      <div class="bookingDashboard__button-wr">
        <a class="bookingDashboard__button" href="#">
          <span class="text">Export</span>
        </a>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Slider from '@/components/dashboard/Slider/Slider'
  import './BookingConfirmation.styl'

  export default {
    name: 'BookingConfirmation',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      booking () {
        return this.$store.state.bookingConfirmation.bookingConfirmation
      },
    },
    components: {
      SvgIcon,
      Slider,
    },
  }
</script>
